import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  CountryData,
  DeletePlantsRequestType,
  GetPlantsRequest,
  GetPlantsResponse,
  PlantsMutationResponse,
  PostPlantsRequest,
  TimezoneData,
  TimezoneFilters,
  UpdatePlantRequestType,
} from './plantsTypes'
import { CountResponse, OrgCountRequest } from 'store/services/utilsTypes'

export const plant = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postPlants: builder.mutation<PlantsMutationResponse, PostPlantsRequest>({
      query: ({ data, org_id }) => {
        return {
          url: `/org/${org_id}/plant`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['PlantsUpdate', 'UserInfo'],
    }),
    getPlants: builder.query<GetPlantsResponse, GetPlantsRequest>({
      query: ({ org_id, filters }) => {
        const URL = buildUrl(`/org/${org_id}/plants`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the plant ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['PlantsUpdate'],
    }),

    editPlant: builder.mutation<PlantsMutationResponse, UpdatePlantRequestType>(
      {
        query: ({ org_id, id, data }) => ({
          url: `org/${org_id}/plant/${id}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }),
        invalidatesTags: ['PlantsUpdate', 'UserInfo'],
      },
    ),
    deletePlant: builder.mutation<
      PlantsMutationResponse,
      DeletePlantsRequestType
    >({
      query: ({ org_id, id }) => ({
        url: `org/${org_id}/plant/${id}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['PlantsUpdate', 'UserInfo'],
    }),

    getPlantsCount: builder.query<CountResponse, OrgCountRequest>({
      query: ({ org_id }) => {
        return {
          url: `org/${org_id}/plants/count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the count Plant')
            }
            return response.json()
          },
        }
      },
      providesTags: ['PlantsUpdate'],
    }),

    getCountry: builder.query<CountryData, void>({
      query: () => {
        return {
          url: '/utils/country',
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the country')
            }
            return response.json()
          },
        }
      },
    }),
    getTimezone: builder.query<TimezoneData, { filters: TimezoneFilters }>({
      query: ({ filters }) => {
        const URL = buildUrl(`/utils/timezone/`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the country')
            }
            return response.json()
          },
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPlantsQuery,
  useDeletePlantMutation,
  useEditPlantMutation,
  useGetCountryQuery,
  usePostPlantsMutation,
  useGetTimezoneQuery,
  useGetPlantsCountQuery,
} = plant
