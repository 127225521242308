import { Dispatch, SetStateAction } from 'react'
import { t } from 'i18next'

import { BaseFilters, Paging } from 'store/services/utilsTypes'
import FirstPageArrow from 'assets/icons/paginated-table/FirstPageArrow'
import LastPageArrow from 'assets/icons/paginated-table/LastPageArrow'
import NextPageArrow from 'assets/icons/paginated-table/NextPageArrow'
import PreviousPageArrow from 'assets/icons/paginated-table/PreviousPageArrow'
import { Table } from '@tanstack/react-table'

const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10_per_page' },
  { value: 25, label: '25_per_page' },
  { value: 50, label: '50_per_page' },
]

const calculatePageRange = (
  pageIndex: number,
  pageSize: number,
  filteredRows: number,
) => {
  const start = filteredRows > 0 ? (pageIndex - 1) * pageSize + 1 : 0
  const end = Math.min(pageIndex * pageSize, filteredRows)
  const totalFilteredRows = filteredRows

  return `${start} - ${end} ${t('of')} ${totalFilteredRows}`
}

interface Props<T> {
  paginationMetaData: Paging
  setFilters?: Dispatch<SetStateAction<BaseFilters>>
  pageNo: number
  pageSize: number
  columnsLength: number
  table: Table<T>
}
const PaginationFooter = <T,>({
  paginationMetaData,
  setFilters,
  pageNo,
  pageSize,
  columnsLength,
  table,
}: Props<T>) => {
  return (
    <div className="flex w-full items-center gap-10 pt-4">
      {/* Button Group */}
      <div className="flex gap-3">
        <button
          data-testid="first-btn"
          onClick={() => {
            if (paginationMetaData.current_page > 1) {
              setFilters &&
                setFilters((prevFilters) => {
                  return {
                    ...prevFilters,
                    page_no: 1,
                  }
                })
            }
          }}
          className={`p-[0.1rem] ${paginationMetaData.current_page > 1 ? '' : 'cursor-default opacity-20'}`}
        >
          <FirstPageArrow />
        </button>
        <button
          data-testid="prev-btn"
          onClick={() => {
            if (paginationMetaData?.current_page > 1) {
              table.setPageIndex(pageNo - 1)
              setFilters &&
                setFilters((prevFilters) => {
                  return {
                    ...prevFilters,
                    page_no: Number(pageNo - 1),
                  }
                })
            }
          }}
          className={`p-[0.1rem] ${paginationMetaData.current_page > 1 ? '' : 'cursor-default opacity-20 '}`}
        >
          <PreviousPageArrow />
        </button>
        <button
          data-testid="next-btn"
          onClick={() => {
            if (pageNo !== paginationMetaData?.total_pages) {
              table.setPageIndex(pageNo + 1)
              setFilters &&
                setFilters((prevFilters) => {
                  return {
                    ...prevFilters,
                    page_no: Number(pageNo + 1),
                  }
                })
            }
          }}
          className={`p-[0.1rem] ${pageNo === paginationMetaData.total_pages ? 'cursor-default opacity-20 ' : ''}`}
        >
          <NextPageArrow />
        </button>
        <button
          data-testid="last-btn"
          onClick={() => {
            if (pageNo !== paginationMetaData.total_pages) {
              table.setPageIndex(paginationMetaData.total_pages)
              setFilters &&
                setFilters((prevFilters) => {
                  return {
                    ...prevFilters,
                    page_no: Number(paginationMetaData.total_pages),
                  }
                })
            }
          }}
          className={`p-[0.1rem] ${pageNo === paginationMetaData.total_pages ? 'cursor-default opacity-20 ' : ''}`}
        >
          <LastPageArrow />
        </button>
      </div>

      {/* Page Size */}
      <div className="flex flex-row items-center gap-2">
        {/* Page size dropdown */}
        <div
          style={{ paddingRight: '4px' }}
          className="rounded border border-[#D2DADF]  py-[2px] outline-none p-xs-regular"
        >
          <select
            className="outline-none p-xs-regular"
            id="pageSizeSelect"
            data-testid="pageSizeSelect"
            value={pageSize}
            onChange={(e) => {
              setFilters &&
                setFilters((prevFilters) => {
                  return {
                    ...prevFilters,
                    page_size: Number(e.target.value),
                  }
                })
            }}
          >
            {PAGE_SIZE_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {t(option.label)}
              </option>
            ))}
          </select>
        </div>

        {/* Page size and Page No statistics */}
        <span className="p-xs-regular-dim">
          {t('showing')}{' '}
          {calculatePageRange(
            paginationMetaData.current_page,
            paginationMetaData.page_size,
            paginationMetaData.total_items_count ?? 0,
          )}
        </span>
      </div>

      {/* No of column information */}
      <div data-testid="columns-count" className="p-xs-regular-dim">
        {t('this_table_has_a_total_of')} {columnsLength} columns.
      </div>
    </div>
  )
}

export default PaginationFooter
