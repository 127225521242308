import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetProcessByIdQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import { useGetWarehousesQuery } from 'pages/warehouses/warehouses-endpoints/warehouses.endpoints'
import { Product } from './EntriesDashboard'
import { isArray } from 'lodash'
import { SORT_DIR } from 'utils/constants'

const warehouseFilters = {
  page_no: 1,
  page_size: 1000,
  search_text: '',
  sort_by: 'created_on',
  sort_dir: SORT_DIR.DESC,
}

const useAddOptionsData = (process_id: string | undefined) => {
  const plantId = useSelector(selectSelectedItem)

  // *************************** Get Operational Process by id ************************************ //

  const { data: processDetails } = useGetProcessByIdQuery(
    {
      process_id,
    },
    { skip: !process_id },
  )

  const [inputOptions, setInputOptions] = useState([])
  const [outputOptions, setOutputOptions] = useState([])
  const [AllfieldDetails, setAllFieldDetails] = useState<any[]>([])

  // used for input and output product

  useEffect(() => {
    if (processDetails) {
      const input = processDetails?.input_products?.map((item: any) => ({
        label: item.product.name,
        value: item.product._id,
        unit_of_measure: item.product?.unit_of_measure ?? '',
        is_accepting_decimal: item.product?.is_accepting_decimal,
      }))
      const output = processDetails?.output_products?.map((item: any) => ({
        label: item.product.name,
        value: item.product._id,
        unit_of_measure: item.product?.unit_of_measure ?? '',
        is_accepting_decimal: item.product?.is_accepting_decimal,
      }))

      setOutputOptions(output)
      setInputOptions(input)
      let data = processDetails?.input_products
        ?.filter((item: any) => item.product._id === activeTab._id)
        ?.map((item: any) => item.fields)

      if (isArray(data) && data.length > 0) {
        data = [
          ...data,
          ...processDetails?.output_products
            ?.filter((item: any) => item.product._id === activeTab._id)
            ?.map((item: any) => item.fields),
        ]
        setAllFieldDetails(data?.flat(1))
      }
    }
  }, [processDetails])

  // *************************** Get Operational Process By Id End ************************************ //

  // *************************** Get WareHouse Details by plant Id ************************************ //

  const { data: warehouses } = useGetWarehousesQuery(
    { plant_id: plantId?.id, filters: warehouseFilters },
    {
      skip: !plantId?.id,
    },
  )

  const [wareHouseOptions, setWareHouseOptions] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [storageAreaOptions, setStorageAreaOptions] = useState<
    {
      label: string
      value: string
    }[]
  >([])

  // used for warehouse details

  useEffect(() => {
    if (warehouses) {
      const data = warehouses?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      }))
      setWareHouseOptions(data)
    }
  }, [warehouses])

  // *************************** Get WareHouse Details by plant Id End ************************************ //

  // *************************** Get Storage Details by selected warehouse Id  ************************************ //

  const handleSelectWareHouse = (id: string) => {
    const area = warehouses?.data
      ?.filter((item) => item.id === id)[0]
      ?.storage_areas?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
    area && setStorageAreaOptions(area)
  }

  const [fieldDetails, setFieldDetails] = useState<any[]>([])

  const handleProductField = (id: string, type: string) => {
    let data: { fields: any[] }[] = []
    if (type === 'INPUT') {
      data = processDetails?.input_products.filter(
        (item: any) => item.product._id === id,
      )
    } else {
      data = processDetails?.output_products.filter(
        (item: any) => item.product._id === id,
      )
    }
    if (data?.length > 0) setFieldDetails(data[0].fields)
  }
  const defaultTab = { name: `All Products`, _id: '', code: '' }
  const [tabs, setTabs] = useState<Product[]>([defaultTab])
  const [activeTab, setActiveTab] = useState<Product>(defaultTab)

  useEffect(() => {
    if (processDetails) {
      const inputProducts = processDetails?.input_products?.map(
        (item: any) => item.product,
      )
      const outputProducts = processDetails?.output_products?.map(
        (item: any) => item.product,
      )

      let newTabs = [defaultTab]
      if (inputProducts?.length > 0) {
        newTabs = [...newTabs, ...inputProducts]
      }
      if (outputProducts?.length > 0) {
        newTabs = [...newTabs, ...outputProducts]
      }
      setTabs(newTabs)
    }
  }, [processDetails])

  useEffect(() => {
    if (processDetails) {
      let data = processDetails?.input_products
        ?.filter((item: any) => item.product._id === activeTab._id)
        ?.map((item: any) => item.fields)

      if (isArray(data) && data.length > 0) {
        data = [
          ...data,
          ...processDetails.output_products
            ?.filter((item: any) => item.product._id === activeTab._id)
            ?.map((item: any) => item.fields),
        ]
        setAllFieldDetails(data.flat(1))
      }
    }
  }, [processDetails, activeTab._id])

  const handleTabClick = (tab: Product) => {
    setActiveTab(tab)
  }

  return {
    process_name: processDetails?.name || '',
    department_name: processDetails?.department?.name || '',
    wareHouseOptions,
    inputOptions,
    outputOptions,
    handleSelectWareHouse,
    storageAreaOptions,
    handleTabClick,
    activeTab,
    tabs,
    handleProductField,
    fieldDetails,
    AllfieldDetails,
  }
}

export default useAddOptionsData
