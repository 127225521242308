import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Chip from 'libs/chip/Chip'
import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import PaginatedTableV2 from 'libs/table/paginated-table/PaginatedTableV2'
import QuickAddEditPurchaseOrder from 'libs/quick-add-edits/quick-add-edit-po/QuickAddEditPoPanel'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { hasAccess } from 'utils/utils'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetVendorByIdQuery } from '../vendor-endpoints/vendor.endpoints'
import { PurchaseOrder } from '../vendor-endpoints/vendorTypes'
import { ColumnDef } from '@tanstack/react-table'

const ViewPurchaseOrders = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const purchaseOrderAccess = hasAccess('ord', { po: 'e' })
  const purchaseOrderViewAccess = hasAccess('ord', { po: 'v' })
  const plant = useSelector(selectSelectedItem)

  const { data: vendor } = useGetVendorByIdQuery(
    { plant_id: plant?.id, id: id ?? '' },
    {
      skip: !plant?.id || !id,
    },
  )
  const columns: ColumnDef<PurchaseOrder>[] = [
    {
      header: 'Purchase Order ID',
      accessorKey: 'purchaseOrderID',
      minSize: 150,
      accessorFn: (row) => {
        return (
          <div
            className={`${purchaseOrderViewAccess ? 'hyperlink' : ''}`}
            onClick={() => {
              purchaseOrderViewAccess &&
                row?.id &&
                navigate(`/orders/purchase-order/view/${row.id}`)
            }}
          >
            {row?.purchaseOrderID ?? '-'}
          </div>
        )
      },
    },
    {
      header: 'Raw Materials',
      accessorKey: 'products',

      minSize: 150,
      accessorFn: (row) => {
        const product = row?.products?.length
        return (
          <div
            className={`${purchaseOrderViewAccess ? 'hyperlink' : ''}`}
            onClick={() => {
              purchaseOrderViewAccess &&
                row?.id &&
                navigate(`/orders/purchase-order/view/${row.id}`)
            }}
          >
            <span>{product} Raw Materials</span>
          </div>
        )
      },
    },

    {
      header: 'Arrival Date',
      accessorKey: 'arrivalDate',
      minSize: 100,
      accessorFn: (row) => formatUnixDate(row?.arrivalDate),
    },
    {
      header: 'Progress',
      accessorKey: 'status',
      minSize: 50,
      accessorFn: (row) => <Chip title={row?.status} />,
    },
  ]

  return (
    <MotionDiv>
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full justify-between">
          <h5 className="h5">Purchase Orders</h5>
          <div>
            {purchaseOrderAccess && (
              <Button
                startIcon={<PlusIcon />}
                color="primary"
                onClick={() => {
                  setIsPanelOpen(true)
                }}
              >
                {'Add Purchase Order'}
              </Button>
            )}
          </div>
        </div>
        <QuickAddEditPurchaseOrder
          onClose={() => setIsPanelOpen(false)}
          isOpen={isPanelOpen}
        />
        <PaginatedTableV2
          columns={columns}
          loading={false}
          rows={vendor?.purchaseOrders ?? []}
          hidePagination={true}
          emptyMessage={
            'No purchase order found. Please add new purchase order'
          }
        />
      </div>
    </MotionDiv>
  )
}

export default ViewPurchaseOrders
