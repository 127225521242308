import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { ColumnDef } from '@tanstack/react-table'

import Filter from 'assets/icons/camera-page/Filter'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import Button from 'libs/button/Button'
import SidePanel from 'libs/sidepanel/SidePanel'
import WhiteCard from 'libs/card/WhiteCard'
import SearchBox from 'libs/searchbox/SearchBox'
import { useDebouncedSearch } from 'libs/hooks/useSearch'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { BaseFilters } from 'store/services/utilsTypes'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'
import PaginatedTableV2 from '../paginated-table/PaginatedTableV2'
import { SORT_DIR } from 'utils/constants'

interface FilterPanelComponentProps {
  closePanel: () => void
  setFilters: Dispatch<SetStateAction<BaseFilters>>
  filters: BaseFilters
}

//Calculate active filter count
const calculateActiveFiltersCount = (
  filters: Record<string, any>,
  excludeKeys: string[],
): number => {
  const excludedKeys = new Set([
    'page_no',
    'page_size',
    'search_text',
    'sort_dir',
    'sort_by',
    ...excludeKeys,
  ])
  return Object.keys(filters).reduce((count, key) => {
    if (excludedKeys.has(key)) return count
    const value = filters[key]
    if (Array.isArray(value) ? value.length > 0 : value) {
      return count + 1
    }
    return count
  }, 0)
}

export interface FilterableSearchableTableProps<T> {
  useQuery: any // Can't use type here, ignore this while reviewing
  useOrgQuery?: any // Can't use type here, ignore this while reviewing
  columns: ColumnDef<T>[]
  excludeKeys?: string[]
  hasModifyAccess?: boolean
  additionalFilters?: Record<string, any> // Can't use type here, ignore this while reviewing
  placeholders: {
    search?: string
    emptyMessage: string
    filterEmptyMessage: string
  }
  addButton?: {
    label: string
    onClick: () => void
  }
  filterPanelComponent?: React.ComponentType<FilterPanelComponentProps>
}

const FilterableSearchableTable = <DataType,>({
  useQuery,
  useOrgQuery,
  columns,
  additionalFilters,
  placeholders,
  addButton,
  excludeKeys = [],
  hasModifyAccess,
  filterPanelComponent: FilterPanelComponent,
}: FilterableSearchableTableProps<DataType>) => {
  // fetching current plant
  const plant = useSelector(selectSelectedItem)

  // fetching user information
  const { data: userInfo } = useUserInfoQuery({})

  // getting role of user
  const [role, setRole] = useState<string>('')

  const [showFilter, setShowFilter] = useState(false)
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch()
  const [filters, setFilters] = useState<BaseFilters>({
    page_no: 1,
    page_size: 10,
    search_text: '',
    sort_by: 'created_on',
    sort_dir: SORT_DIR.DESC,
    ...additionalFilters,
  })

  //*************************************This API will be called for the perticular plant **************************** */
  const {
    data: paginatedData,
    isLoading,
    isFetching,
  } = useQuery(
    {
      plant_id: plant?.id,
      filters: filters,
    },
    {
      skip: !plant?.id,
    },
  )

  //**********************************************This API will be called for perticular organization ******************* */

  const result =
    useOrgQuery &&
    useOrgQuery(
      {
        org_id: userInfo?.organization?._id,
        filters: filters,
      },
      {
        skip: !userInfo?.organization?._id,
        refetchOnMountOrArgChange: true,
      },
    )

  const { data: plantData, isFetching: isPlantDataFetching } = result ?? {
    data: null,
    isFetching: false,
  }

  const activeFiltersCount = calculateActiveFiltersCount(filters, excludeKeys)

  // setting search text
  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page_no: 1,
      search_text: searchValue,
    }))
  }, [searchValue])

  // get role of user
  useEffect(() => {
    const _role = Cookies.get('role')
    if (_role) {
      setRole(_role)
    }
  }, [Cookies.get('role')])

  return (
    <>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <WhiteCard className="mt-4">
          <div className="mb-2 flex justify-between">
            <div className="flex w-full gap-3">
              {FilterPanelComponent != null && (
                <Button
                  onClick={() => setShowFilter((prev) => !prev)}
                  startIcon={<Filter color="#3D4140" />}
                >
                  Filters {activeFiltersCount > 0 && `(${activeFiltersCount})`}
                </Button>
              )}
              {placeholders.search && (
                <SearchBox
                  value={inputValue}
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder={placeholders.search}
                  onClearSearchBox={() => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      search_text: '',
                    }))
                    handleSearch('')
                  }}
                />
              )}
            </div>
            <div className="relative mx-2 flex gap-3">
              {addButton != null && (hasModifyAccess || role == 'admin') && (
                <Button
                  startIcon={<PlusIcon />}
                  color="primary"
                  onClick={addButton.onClick}
                >
                  {addButton.label}
                </Button>
              )}
            </div>
          </div>

          <PaginatedTableV2
            filters={filters}
            setFilters={setFilters}
            columns={columns}
            sorting={{
              sort_by: filters.sort_by ?? 'created_on',
              sort_dir: filters.sort_dir ?? 'DESC',
            }}
            rows={(paginatedData?.data || plantData?.data) ?? []}
            loading={isFetching || isPlantDataFetching}
            paginationMetaData={paginatedData?.paging || plantData?.paging}
            emptyMessage={`${filters.search_text ? `${placeholders.filterEmptyMessage}` : activeFiltersCount > 0 ? placeholders.filterEmptyMessage : placeholders.emptyMessage}`}
          />

          {FilterPanelComponent != null && showFilter && (
            <SidePanel
              size="large"
              isOpen={showFilter}
              title="Filters"
              onClose={() => {
                setShowFilter(false)
              }}
            >
              <FilterPanelComponent
                closePanel={() => {
                  setShowFilter(false)
                }}
                filters={filters}
                setFilters={setFilters}
              />
            </SidePanel>
          )}
        </WhiteCard>
      )}
    </>
  )
}

export default FilterableSearchableTable
