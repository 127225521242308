import { useEffect } from 'react'
import Products from 'assets/icons/dashboard/Products'
import Department from 'assets/icons/dashboard/Department'
import OperationalProcesses from 'assets/icons/configure/OperationalProcesses'
import { useSelector, useDispatch } from 'react-redux'
import { selectSelectedItem, setNavActive } from 'store/redux/navSlice'
import { useGetAllProcessesQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import { useGetDepartmentCountQuery } from 'pages/department/department-endpoints/department.endpoints'
import { useTranslation } from 'react-i18next'
import { useGetProductCountQuery } from 'pages/products/products-endpoints/products.endpoints'
import { useGetWarehouseAndStorageCountQuery } from 'pages/warehouses/warehouses-endpoints/warehouses.endpoints'
import Card from 'libs/card/Card'
import { useNavigate } from 'react-router-dom'
import MotionDiv from 'libs/motionDiv'
import { formatNumber } from 'utils/numberHandlers'
import Warehouse from 'assets/icons/dashboard/Warehouse'
import Button from 'libs/button/Button'
import PlantsIcon from 'assets/icons/dashboard/PlantsIcon'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useGetPlantsCountQuery } from 'pages/plants/plants-endpoints/plants.endpoints'
import { useUserInfoQuery } from '../user-management/users/user-endpoints/user.endpoint'
import { useGetWorkCenterCountQuery } from 'pages/work-center/work-center-endpoints/workcenter.endpoint'
import RoutingIcon from 'assets/icons/routings/RoutingIcon'
import { useGetRoutingsCountQuery } from 'pages/routings/routings-endpoints/routings.endpoints'

const Configure = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedItem = useSelector(selectSelectedItem)
  const { data } = useUserInfoQuery({})

  const navigate = useNavigate()
  const { data: departmentCount, isLoading: departmentLoading } =
    useGetDepartmentCountQuery(
      {
        plant_id: selectedItem?.id,
      },
      {
        skip: !selectedItem?.id,
      },
    )
  const { data: productCount, isLoading: productsLoading } =
    useGetProductCountQuery(
      {
        plant_id: selectedItem?.id,
      },
      {
        skip: !selectedItem?.id,
      },
    )
  const { data: workCenterCount, isLoading: workCenterLoading } =
    useGetWorkCenterCountQuery(
      {
        plantId: selectedItem?.id,
      },
      {
        skip: !selectedItem?.id,
      },
    )

  const { data: warehouseCount, isLoading: warehouseCountLoading } =
    useGetWarehouseAndStorageCountQuery(
      {
        plant_id: selectedItem?.id,
      },
      {
        skip: !selectedItem?.id,
      },
    )

  const { data: plantsCount, isLoading: plantsCountLoading } =
    useGetPlantsCountQuery(
      {
        org_id: data?.organization?._id ?? '',
      },
      {
        skip: !data?.organization?._id,
      },
    )

  // for get product count
  const plantId = useSelector(selectSelectedItem)
  const { data: allProcessData, isLoading: processLoading } =
    useGetAllProcessesQuery(
      {
        plantId: plantId.id,
        filters: {
          page_no: 1,
          page_size: 10,
          searchText: '',
        },
      },
      {
        skip: !plantId.id,
      },
    )

  // Routings Count
  const { data: routingsCount } = useGetRoutingsCountQuery(
    {
      plant_id: plantId?.id,
    },
    {
      skip: !plantId?.id,
    },
  )

  useEffect(() => {
    dispatch(setNavActive())
  }, [])

  const cardDetails = [
    {
      icon: <Products color={undefined} />,
      title: 'products',
      description: 'Tap to add new product',
      total: 'Total products',
      route: '/settings/configure/products',
      addroute: '/settings/configure/products',
      buttonText: 'See Details',
      TotalProcesses: `${productCount?.active_count ?? '-'}`,
    },
    {
      icon: <OperationalProcesses />,
      title: 'operational_processes',
      description: 'tap_to_add_new_process',
      total: 'Total operational processes',
      route: '/settings/configure/operationalprocesses',
      addroute: '/settings/configure/operationalprocesses',
      buttonText: 'see_details',
      TotalProcesses: `${allProcessData?.paging?.total_items_count ?? '-'}`,
    },
    {
      icon: <Department />,
      title: 'Departments',
      description: 'tap_to_add_new_process',
      total: 'departments.total_departments',
      route: '/settings/configure/department',
      addroute: '/settings/configure/department',
      buttonText: 'see_details',
      TotalProcesses: `${departmentCount?.active_count ?? '-'}`,
    },
    {
      icon: <Department />,
      title: 'Work Centers',
      description: 'total work centers',
      total: 'Total work centers',
      route: '/settings/configure/work-centers',
      addroute: '/settings/configure/work-centers',
      buttonText: 'see_details',
      TotalProcesses: `${workCenterCount?.count ?? '-'}`,
    },

    {
      icon: <Warehouse color={undefined} />,
      title: 'warehouses',
      description: 'Tap to add new product',
      total: 'Total warehouse',
      route: '/settings/configure/warehouses',
      addroute: '/settings/configure/warehouses',
      buttonText: 'See Details',
      TotalProcesses: `${warehouseCount?.warehouse_count ?? '-'}`,
    },
    {
      icon: <PlantsIcon color={undefined} />,
      title: 'Plants',
      description: 'Tap to add new product',
      total: 'Total plants',
      route: '/settings/configure/plants',
      addroute: '/settings/configure/plants',
      buttonText: 'See Details',
      TotalProcesses: `${plantsCount?.count ?? '-'}`,
    },
    {
      icon: <RoutingIcon />,
      title: 'Routings',
      description: 'Tap to add new routing',
      total: 'Total routings',
      route: '/settings/configure/routings',
      addroute: '/settings/configure/routings',
      buttonText: 'See Details',
      TotalProcesses: `${routingsCount?.count ?? '-'}`,
    },
  ]

  const isLoading =
    productsLoading ||
    warehouseCountLoading ||
    processLoading ||
    departmentLoading ||
    plantsCountLoading ||
    workCenterLoading

  return (
    <MotionDiv>
      <div className="h-full">
        <h1 className="h4-bold-black  ">{t('configure')}</h1>
        <p className="mb-2 p-sm-regular">
          {t(
            'configure_and_monitor_your_plant_operations_create_products_processes_and_forms_to_capture_data_from_plant_floor',
          )}
        </p>

        <div className="relative h-full w-full">
          {isLoading ? (
            <div className="absolute left-64 top-16 flex items-center justify-center">
              <FullPageLoader />
            </div>
          ) : (
            <div className="mt-6 grid h-full w-full grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3">
              {cardDetails.map((card, index) => (
                <div key={`${index}_${card.title}`}>
                  <Card
                    key={`${index}_${card.title}`}
                    title={card?.title}
                    subtitle={card.total}
                    totalCount={
                      <span data-testid="count">
                        {formatNumber(Number(card.TotalProcesses))}
                      </span>
                    }
                    leftIcon={card.icon}
                    rightCenterIcon={
                      <Button data-testid="card-view-button" color="primary">
                        {' '}
                        View{' '}
                      </Button>
                    }
                    onClick={() => navigate(card.route)}
                    titleStyle="font-medium text-gray-800"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </MotionDiv>
  )
}

export default Configure
