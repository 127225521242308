interface Props {
  emptyMessage: string
}

const EmptyMessageRow = ({ emptyMessage }: Props) => {
  return (
    <div
      className={
        'rounded-b-md border px-2  py-2  text-center p-xs-regular hover:bg-primary-200'
      }
    >
      {emptyMessage}
    </div>
  )
}

export default EmptyMessageRow
