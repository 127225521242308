import Button from 'libs/button/Button'
import { Plant } from '../plants-endpoints/plantsTypes'

type Props = {
  plant: Plant
  closePanel: () => void
  handleDelete: (entryData: Plant) => void
  handleUpdate: (entryData: Plant) => void
}
const ViewPlant = ({
  plant,
  closePanel,
  handleUpdate,
  handleDelete,
}: Props) => {
  const toggleUpdate = () => {
    handleUpdate(plant)
    closePanel()
  }

  return (
    <>
      <div className="my-4 rounded-lg bg-slate-200 p-4">
        <div className="flex justify-between">
          <div className="mb-2 p-xs-regular-dim">Plant Name</div>
          <div>
            <div className="w-60 break-words p-xs-regular">{plant?.name ?? '-'}</div>
          </div>
        </div>
        <div className="flex justify-between ">
          <div className="mb-2 p-xs-regular-dim">Country</div>
          <div>
            <div className="w-60 break-words p-xs-regular">
              {plant?.country?.countryName ?? '-'}
            </div>
          </div>
        </div>
        <div className="flex justify-between ">
          <div className="mb-2 p-xs-regular-dim">State</div>
          <div>
            <div className="w-60 break-words p-xs-regular">{plant?.state ?? '-'}</div>
          </div>
        </div>
        <div className="flex justify-between ">
          <div className="mb-2 p-xs-regular-dim">City</div>
          <div>
            <div className="w-60 break-words p-xs-regular">{plant?.city ?? '-'}</div>
          </div>
        </div>
        <div className="flex justify-between ">
          <div className="mb-2 p-xs-regular-dim">Street Area</div>
          <div>
            <div className="w-60 break-words p-xs-regular">
              {plant?.area_name ?? '-'}
            </div>
          </div>
        </div>
        <div className="flex justify-between ">
          <div className="mb-2 p-xs-regular-dim">Zipcode</div>
          <div>
            <div className="w-60 break-words p-xs-regular">
              {plant?.zip_code ?? '-'}
            </div>
          </div>
        </div>
        <div className="flex justify-between ">
          <div className="mb-2 p-xs-regular-dim">Timezone</div>
          <div>
            <div className="w-60 break-words p-xs-regular">
              {plant?.timezone ?? '-'}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="mt-2"></div>
        </div>
      </div>
      {plant?.is_active ? (
        <div className="mt-5 flex justify-between ">
          <div>
            <Button onClick={() => handleDelete(plant)} color="error">
              {' '}
              Delete
            </Button>
          </div>
          <div className="flex gap-2">
            <Button onClick={close}>Close</Button>
            <Button onClick={toggleUpdate} color="primary">
              Edit
            </Button>
          </div>
        </div>
      ) : (
        <p className="text-lg font-normal text-[#75838D] p-xs-regular">
          This plant is inactive. To recover any data related to this plant or
          to reactivate it, please contact our tech support at
          support@catalystdomainname.
        </p>
      )}
    </>
  )
}

export default ViewPlant
