import { Dispatch, SetStateAction, useMemo } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  ColumnDef,
} from '@tanstack/react-table'
import Loader from 'libs/loader/customloader/Loader'
import { BaseFilters, Paging } from 'store/services/utilsTypes'
import BodyCell from './components/BodyCell'
import HeaderCell from './components/HeaderCell'
import EmptyMessageRow from './components/EmptyMessageRow'
import PaginationFooter from './components/PaginationFooter'

// Define your custom column metadata
interface AdditionalColumnMeta {
  align?: 'left' | 'center' | 'right'
}

declare module '@tanstack/react-table' {
  //  @ts-ignore
  interface ColumnMeta extends AdditionalColumnMeta {}
}

interface PaginatedTableProps<T> {
  columns: ColumnDef<T>[]
  rows: T[]
  emptyMessage: string
  loading: boolean
  sorting?: { sort_by: string; sort_dir: 'ASC' | 'DESC' }
  filters?: BaseFilters
  setFilters?: Dispatch<SetStateAction<BaseFilters>>
  hidePagination?: boolean
  paginationMetaData?: Paging
  showArrow?: boolean
  // onSelectAll?: (rows: Row<T>[]) => void
}
const PaginatedTableV2 = <T,>({
  columns,
  rows,
  emptyMessage,
  sorting,
  filters,
  setFilters,
  hidePagination,
  paginationMetaData,
  loading,
  // showCheckbox = false,
}: PaginatedTableProps<T>) => {
  const pageNo = filters?.page_no ?? 0
  const pageSize = filters?.page_size ?? 0

  const table = useReactTable({
    data: rows ?? [],
    columns,
    state: {
      pagination: {
        pageIndex: pageNo - 1,
        pageSize: pageSize,
      },
      sorting: sorting
        ? [{ id: sorting.sort_by, desc: sorting.sort_dir === 'DESC' }]
        : [],
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    defaultColumn: {
      size: 200, //starting column size
      minSize: 150, //enforced during column resizing
      maxSize: 500, //enforced during column resizing
    },
  })

  const tableRows = useMemo(
    () => Object.values(table.getRowModel().rowsById),
    [table, rows, sorting],
  )

  const tableCols = useMemo(() => {
    return table.getHeaderGroups()
  }, [table, columns])

  const getRowClassName = (isEvenRow = false) => {
    return `sticky-row1 border-x border-gray-100  hover:bg-primary-200  
    ${isEvenRow ? 'bg-white' : 'bg-gray-100'}`
  }

  return (
    <div className="overflow-x-auto rounded-b-md rounded-t-md ">
      <table className="min-w-full">
        <thead className="top-0  bg-red-200 p-2">
          {tableCols.map((headerGroup) => {
            return (
              <tr key={headerGroup.id} className="bg-red-200 p-2">
                {headerGroup.headers.map((header) => {
                  const isLastColumn =
                    header.index === headerGroup.headers?.length - 1
                  return (
                    <HeaderCell
                      key={header.id}
                      header={header}
                      isLastColumn={isLastColumn}
                      setFilters={setFilters}
                      sorting={sorting}
                    />
                  )
                })}
              </tr>
            )
          })}
        </thead>
        <tbody>
          {tableRows.map((row, rowIndex: number) => {
            const cells = row.getAllCells()
            const isEvenRow = rowIndex % 2 === 0
            return (
              <tr key={row.id} className={getRowClassName(isEvenRow)}>
                {cells.map((cell, cellIndex: number) => {
                  const isLastCell = cellIndex === cells?.length - 1
                  return (
                    <BodyCell
                      key={cell.id}
                      cell={cell}
                      isLastCell={isLastCell}
                      isEvenRow={isEvenRow}
                    />
                  )
                })}
              </tr>
            )
          })}
          {loading && rows.length ? (
            <div className="absolute left-1/2 top-1/2  z-30">
              <Loader size="xl" color="primary" />
            </div>
          ) : (
            <></>
          )}
        </tbody>
      </table>
      {!tableRows?.length ? (
        <EmptyMessageRow emptyMessage={emptyMessage} />
      ) : null}
      {!hidePagination && paginationMetaData && rows?.length ? (
        <PaginationFooter
          paginationMetaData={paginationMetaData}
          setFilters={setFilters}
          pageNo={pageNo}
          pageSize={pageSize}
          columnsLength={columns.length}
          table={table}
        />
      ) : null}
    </div>
  )
}

export default PaginatedTableV2
