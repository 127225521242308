import { Header } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import { BaseFilters } from 'store/services/utilsTypes'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import { SORT_DIR } from 'utils/constants'
interface Props<T> {
  header: Header<T, unknown>
  isLastColumn: boolean
  setFilters?: Dispatch<SetStateAction<BaseFilters>>
  sorting?: { sort_by: string; sort_dir: 'ASC' | 'DESC' }
}

const HeaderCell = <T,>({
  header,
  isLastColumn,
  setFilters,
  sorting,
}: Props<T>) => {
  const align = header.column.columnDef?.meta?.['align']
  const thClassName = ` bg-[#000000E1] px-2 py-2 text-start p-xs-regular group 
        ${header.column.columnDef.enableSorting && 'cursor-pointer'}    
      `
  return (
    <th
      key={header.id}
      colSpan={header.colSpan}
      style={{
        // width: `${header.column.columnDef.size}px`,
        minWidth: `${header.column.columnDef.minSize}px`,
        maxWidth: `${header.column.columnDef.maxSize}px`,
      }}
      className={thClassName}
      onClick={() => {
        header.column.columnDef.enableSorting &&
          setFilters &&
          setFilters((prevFilters) => {
            const newsort_dir =
              prevFilters?.sort_by === header.id &&
              prevFilters?.sort_dir === SORT_DIR.ASC
                ? SORT_DIR.DESC
                : SORT_DIR.ASC
            return {
              ...prevFilters,
              sort_by: header.id,
              sort_dir: newsort_dir,
            }
          })
      }}
    >
      <div className="flex items-center justify-between ">
        <div
          className={`space-between flex   gap-1 border-[#75838D]
        ${align === 'right' ? ' justify-end ' : ''}
        ${align === 'center' ? ' justify-center ' : ''}   
      `}
          style={{
            color: '#FFFFFF',
          }}
        >
          {/* {showCheckbox && index === 0 && (
            <span className="ml-2 flex items-center">
              <input
                data-testid="select-all-checkbox"
                type="checkbox"
                className="form-checkbox mr-1 h-3 w-3 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
            </span>
          )} */}

          <span
            data-testid="column-text"
            className="flex flex-row items-center"
          >
            {typeof header.column.columnDef.header === 'function'
              ? header.column.columnDef.header(header.getContext())
              : header.column.columnDef.header}

            {sorting && header.column.columnDef.enableSorting && (
              <span
                className={`ml-2  opacity-10 group-hover:opacity-100
              ${!(sorting.sort_by === header.id && sorting.sort_dir === 'ASC') ? 'duration-200' : 'rotate-180 duration-200'}
              `}
              >
                <KeyboardArrowDownRounded fontSize="small" />
              </span>
            )}
          </span>
        </div>
        {!isLastColumn && (
          <span className="ml-2 h-6 w-2  border-r border-gray-500 text-2xl text-gray-400"></span>
        )}
      </div>
    </th>
  )
}

export default HeaderCell
