import { useState } from 'react'
import { toast } from 'libs/toast'
import { useTranslation } from 'react-i18next'
import Button from 'libs/button/Button'
import { useDeletePlantMutation } from 'pages/plants/plants-endpoints/plants.endpoints'
import { Plant } from '../plants-endpoints/plantsTypes'

interface Props {
  plant: Plant
  closePopUp: () => void
}

function DeletePlant({ plant, closePopUp }: Props) {
  const [deletePlant, { isLoading }] = useDeletePlantMutation()
  const [formData, setFormData] = useState({ name: '' })
  const { t } = useTranslation()
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }
  const handleDelete = () => {
    if (plant?.id && formData.name === plant?.name) {
      deletePlant({ org_id: plant?.organization[0]?.id, id: plant?.id })
        .unwrap()
        .then((res) => {
          toast.success(res)
          closePopUp()
        })
        .catch((error) => {
          toast.error(
            error?.data?.detail ??
              `Failed to remove a Plant. Please try again.`,
          )
        })
    }
  }
  return (
    <div>
      <p className="p-xs-regular">
        {t('delete_text')}
        <span className="break-words p-xs-bold">
          &quot;{plant?.name}&quot;?
        </span>
      </p>
      <p className="p-xs-regular">
        Your historical data related to this plant will remain unaffected.
        However, to recover any data related to this plant, please contact our
        tech support at support@catalystdomainname.
      </p>

      <div>
        <label className="mb-1 p-sm-regular">
          Plant name <span className="text-error">*</span>
        </label>
        <div>
          <div className="relative py-1">
            <input
              required
              type="text"
              name="name"
              value={formData?.name}
              onChange={handleInputChange}
              className={`h-[28px] w-full rounded border border-solid border-[#E0E0E0] p-2 pr-8 text-sm outline-none p-xs-regular`}
              placeholder="Please enter plant name to delete"
            />
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-end gap-2">
        <Button onClick={closePopUp}>Close</Button>
        <Button
          disabled={formData.name !== plant?.name}
          onClick={handleDelete}
          loading={isLoading}
          color="error"
        >
          {' '}
          Delete
        </Button>
      </div>
    </div>
  )
}

export default DeletePlant
