import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Popup from 'libs/popup/Popup'
import SidePanel from 'libs/sidepanel/SidePanel'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import MotionDiv from 'libs/motionDiv'
import WhiteCard from 'libs/card/WhiteCard'
import useTabTitle from 'libs/hooks/useTabTitle'
import FullPageLoader from 'libs/loader/FullPageLoader'
import QuickAddEditVendor from 'libs/quick-add-edits/quick-add-edit-vendor/QuickAddEditVendor'
import { getTodaysDateTimeInUnix } from 'utils/dateTimeHandlers'
import PurchaseSavePopup from 'pages/manufacture-orders/saved-changes-popup/PurchaseSavePopup'
import { STATUS_OPTIONS } from 'pages/orders/sales-order/sales-order-endpoints/salesOrders.constants'
import { PRODUCT_CATEGORY } from 'pages/products/constants'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import { useGetVendorsQuery } from 'pages/contacts/vendor-management/vendor-endpoints/vendor.endpoints'
import { useGetPurchaseOrderByIDQuery } from 'pages/orders/purchase-order/purchase-orders-endpoints/purchaseOrders.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'

import {
  usePostPurchaseOrderMutation,
  usePutPurchaseOrderMutation,
} from '../purchase-orders-endpoints/purchaseOrders.endpoints'
import { PurchaseOrderFormik } from '../purchase-orders-endpoints/purchaseOrdersTypes'
import NewEditableTable from 'libs/table/NewEditableTable'
import { ProductFormik } from 'pages/orders/sales-order/sales-order-endpoints/salesOrdersTypes'
import { SORT_DIR } from 'utils/constants'

const productFilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'updated_on',
  sortDir: 'DESC',
  searchText: '',
}

const filters = {
  page_no: 1,
  page_size: 1000,
  sort_dir: SORT_DIR.DESC,
}

const AddEditPurchaseOrder = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  useTabTitle(id ? 'Edit Purchase Order' : 'Add Purchase Order')

  const plantId = useSelector(selectSelectedItem)
  const { from } = location.state || {}

  const [savedChangesPopUp, setSavedChangesPopUp] = useState({
    isPopUpOpen: false,
  })
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [data, setData] = useState<ProductFormik[]>([])

  // Access the state object
  const { data: vendors } = useGetVendorsQuery(
    {
      plant_id: plantId?.id,
      filters: filters,
    },
    { skip: !plantId?.id },
  )

  const vendorsDataOption = useMemo(
    () =>
      vendors?.data?.map((vendor) => ({
        _id: vendor.id,
        label: vendor.name,
        sublabel: vendor.primaryContactNumber,
        value: vendor?.id,
      })),
    [vendors],
  )

  const { data: purchaseOrder, isLoading: purchaseOrderLoading } =
    useGetPurchaseOrderByIDQuery(
      {
        plant_id: plantId?.id,
        id: id ?? '',
      },
      {
        skip: !plantId.id || !id,
      },
    )
  const [addPurchaseOrder, { isLoading: addPurchaseOrderLoading }] =
    usePostPurchaseOrderMutation()

  const [updatePurchaseOrder, { isLoading: updatePurchaseOrderLoading }] =
    usePutPurchaseOrderMutation()

  const { data: products, isLoading: productLoading } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: productFilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  const onAddPurchaseOrderSuccess = (res: string) => {
    toast.success(res)
    if (from) {
      setSavedChangesPopUp({ isPopUpOpen: true })
    } else {
      navigate(-1)
    }
  }

  const onUpdatePurchaseOrderSuccess = (res: string) => {
    toast.success(res)
    if (from) {
      setSavedChangesPopUp({ isPopUpOpen: true })
    } else {
      navigate(-1)
    }
  }

  const purchaseOrderFormik = useFormik<PurchaseOrderFormik>({
    initialValues: {
      purchaseOrderID: '',
      status: '',
      vendorID: '',
      arrivalDate: getTodaysDateTimeInUnix(),
      description: null,
      products: [],
    },
    validationSchema: Yup.object().shape({
      purchaseOrderID: Yup.string().required('Purchase Order ID is required'),
      status: Yup.string().required('Status is required'),
      vendorID: Yup.string().required('Supplier Name is required'),
      arrivalDate: Yup.string().required('Arrival Date is required'),
    }),
    onSubmit: (values) => {
      const payload = {
        vendorID: values.vendorID,
        purchaseOrderID: values.purchaseOrderID,
        arrivalDate: values.arrivalDate,
        status: values.status,
        description: values.description,
        products:
          data
            ?.filter((d) => d?.product)
            .map((d) => ({
              product_id: d?.product._id,
              quantity: 1 * d.quantity,
            })) || [],
      }

      if (payload?.products?.length <= 0) {
        toast.error('At least one product must be added')
        return
      }

      if (!id) {
        addPurchaseOrder({ plant_id: plantId?.id, data: payload })
          .unwrap()
          .then((res) => onAddPurchaseOrderSuccess(res))
          .catch((error) => {
            toast.error(
              error?.data?.detail ??
                'Something went wrong while creating the purchase order',
            )
          })
      } else {
        updatePurchaseOrder({
          plant_id: plantId.id,
          id,
          data: payload,
        })
          .unwrap()
          .then((res) => onUpdatePurchaseOrderSuccess(res))
          .catch((error) => {
            toast.error(
              error?.data?.detail ??
                'Something went wrong while updating the purchase order',
            )
          })
      }
    },
  })

  const productOptions = useMemo(
    () =>
      products?.data
        ?.filter((item: any) => item.category === PRODUCT_CATEGORY.RAW_MATERIAL)
        .map((item: any) => ({
          id: item._id,
          label: item.name,
          value: item,
        })),
    [products],
  )

  const productsColumns = useMemo(
    () => [
      {
        title: 'Products',
        accessor: 'product',
        flex: 2,
        main: true,
        editable: true,
        placeholder: 'Select Product',
      },
      {
        title: 'Quantity',
        accessor: 'quantity',
        editable: true,
      },
      {
        title: 'UOM',
        accessor: 'unit_of_measure',
      },
    ],
    [],
  )

  // updating sales order
  useEffect(() => {
    if (purchaseOrder) {
      purchaseOrderFormik.resetForm({
        values: {
          purchaseOrderID: purchaseOrder.purchaseOrderID,
          status: purchaseOrder.status,
          arrivalDate: purchaseOrder?.arrivalDate,
          description: purchaseOrder?.description ?? '',
          vendorID: purchaseOrder.vendor?.id ?? '',
          products: purchaseOrder.products.map((product) => ({
            product: { ...product, _id: product.id },
            quantity:
              typeof product?.quantity !== 'number' ? 0 : product?.quantity,
          })),
        },
      })
    }
  }, [purchaseOrder])

  const openPanel = () => {
    setTimeout(() => {
      setIsPanelOpen(!isPanelOpen)
    }, 300)
  }

  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">
              {id ? 'Edit Purchase Order' : 'Add Purchase Order'}
            </h1>
          </div>
        </div>
        {purchaseOrderLoading || productLoading ? (
          <FullPageLoader />
        ) : (
          <WhiteCard className="mt-3 flex flex-col gap-6">
            <span>
              <h5 className="h5">Purchase Order Details</h5>
              <p className="p-sm-regular-1">
                {id
                  ? 'Edit the existing Purchase Order details to make necessary changes and updates.'
                  : 'Add the basic details that are required to create new Purchase Order.'}
              </p>
            </span>
            <div className=" flex flex-col gap-6">
              {/* --- process name and department */}
              <div className="grid  grid-cols-2 gap-x-4 gap-y-2 sm:w-full lg:w-10/12">
                <Field
                  type="text"
                  required={true}
                  label="Purchase Order ID"
                  formik={purchaseOrderFormik}
                  name="purchaseOrderID"
                  placeholder="Enter Purchase Order ID"
                />
                <Field
                  type="select"
                  required={true}
                  label="Status"
                  formik={purchaseOrderFormik}
                  options={id ? STATUS_OPTIONS : STATUS_OPTIONS.slice(0, 2)}
                  name="status"
                  defaultValue={STATUS_OPTIONS?.find(
                    (option) =>
                      option?.value === purchaseOrderFormik?.values?.status,
                  )}
                  placeholder="Enter Status"
                />

                <Field
                  type="select"
                  required={true}
                  label="Supplier Name"
                  formik={purchaseOrderFormik}
                  options={vendorsDataOption}
                  defaultValue={vendorsDataOption?.find(
                    (option) =>
                      option?._id === purchaseOrderFormik?.values?.vendorID,
                  )}
                  name="vendorID"
                  placeholder="Enter Supplier Name"
                  title={'Add Supplier'}
                  onAdd={openPanel}
                />
                <Field
                  type="date"
                  required={true}
                  label="Arrival Date"
                  shouldDisablePastDate
                  formik={purchaseOrderFormik}
                  name="arrivalDate"
                />
                <Field
                  type="text"
                  label="Description"
                  formik={purchaseOrderFormik}
                  name="description"
                  placeholder="Enter Description"
                />
              </div>

              {/* ---- border ----- */}
              <hr></hr>
              {/* ---- border ----- */}

              <NewEditableTable
                defaultRows={purchaseOrderFormik.values.products}
                columns={productsColumns}
                options={productOptions}
                uniqueIdInOptions="_id"
                addButtonTitle="Add Product"
                onChange={(values) => {
                  setData(values)
                }}
              />

              <div
                className={`flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}
              >
                <Button
                  data-testid="cancel-button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  loading={
                    addPurchaseOrderLoading || updatePurchaseOrderLoading
                  }
                  onClick={() => purchaseOrderFormik.handleSubmit()}
                >
                  {id ? 'Save' : 'Add'}
                </Button>
              </div>
            </div>
            {/* Saved Changes Popup */}
            <Popup
              isOpen={savedChangesPopUp.isPopUpOpen}
              title="Saved Changes"
              onClose={() => {
                setSavedChangesPopUp({ isPopUpOpen: false })
              }}
            >
              <PurchaseSavePopup />
            </Popup>
            <SidePanel
              size="large"
              title="Add Vendor"
              isOpen={isPanelOpen}
              onClose={() => setIsPanelOpen(false)}
            >
              <QuickAddEditVendor close={() => setIsPanelOpen(false)} />
            </SidePanel>
          </WhiteCard>
        )}
      </>
    </MotionDiv>
  )
}

export default AddEditPurchaseOrder
