import { useMemo, useState } from 'react'
import { CellProps } from 'react-table'

import MotionDiv from 'libs/motionDiv'
import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import Popup from 'libs/popup/Popup'
import Chip from 'libs/chip/Chip'
import SidePanel from 'libs/sidepanel/SidePanel'
import FilterableSearchTable from 'libs/table/filterable-search-table_new/FilterableSearchTable'
import AddPlant from './add-plant/AddPlant'
import ViewPlant from './view-plant/ViewPlant'
import UpdatePlant from './update-plant/UpdatePlant'
import DeletePlant from './delete-plant/DeletePlant'
import FilterPlant from './filter-plant/FilterPlant'
import { useGetPlantsQuery } from 'pages/plants/plants-endpoints/plants.endpoints'
import { Plant } from './plants-endpoints/plantsTypes'
import { ExtendedColumn } from 'store/services/utilsTypes'

const additionalFilters = {
  country: [],
  timezone: [],
  status: [],
}

const Plants = () => {
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false)
  const [isAddPlantPanelOpen, setIsAddPlantPanelOpen] = useState(false)
  const [viewPanel, setViewPanel] = useState(false)
  const [selectedPlant, setSelectedPlant] = useState<Plant | null>(null)
  const [deletePopup, setDeletePopUp] = useState(false)

  //toggle edit entry
  const toggleUpdatePanel = (entryData: Plant) => {
    setSelectedPlant(entryData)
    setIsEditPanelOpen(!isEditPanelOpen)
  }
  const toggleDeletePanel = (entryData: Plant) => {
    setSelectedPlant(entryData)
    setDeletePopUp(!deletePopup)
  }

  const handleAddPlantToggle = () => {
    setIsAddPlantPanelOpen(!isAddPlantPanelOpen)
  }

  const columns: ExtendedColumn<Plant>[] = useMemo(
    () => [
      {
        Header: <div className="flex h-2 w-full items-center">Plant name</div>,
        accessor: 'name',
        Cell: ({ row }: CellProps<Plant>) => {
          const data = row?.original
          const handlePlantsSelection = (entryData: Plant) => {
            entryData && setSelectedPlant(entryData)
            setViewPanel(!viewPanel)
          }
          return (
            <div
              className="hyperlink"
              onClick={() => handlePlantsSelection(data)}
            >
              {data?.name ?? '-'}
            </div>
          )
        },
        width: 200,
      },
      {
        Header: <div className="flex h-2 w-full items-center">Time zone</div>,
        accessor: 'timezone',
        width: 200,
        Cell: ({ row }: CellProps<Plant>) => {
          return <div>{row.original?.timezone ?? '-'}</div>
        },
      },
      {
        Header: <div className="flex h-2 w-full items-center">Zip code</div>,
        accessor: 'zip_code',
        width: 120,
        Cell: ({ row }: CellProps<Plant>) => {
          return <div>{row.original?.zip_code ?? '-'}</div>
        },
      },
      {
        Header: (
          <div className="flex h-2 w-full items-center justify-end">
            Country
          </div>
        ),
        accessor: 'country',
        width: 144,
        Cell: ({ row }: CellProps<Plant>) => {
          return (
            <div className="flex items-center justify-end pr-6">
              {row.original?.country?.countryName ?? '-'}
            </div>
          )
        },
      },
      {
        Header: <div className="flex h-2 w-full items-center">Address</div>,
        accessor: 'area_name',
        width: 239,
        Cell: ({ row }: CellProps<Plant>) => {
          return (
            <div className="flex items-center justify-end pr-6">
              {row.original?.area_name ?? '-'}
            </div>
          )
        },
      },
      {
        Header: <div className="flex h-2 w-full items-center">Status</div>,
        accessor: 'is_active',
        width: 92,
        Cell: ({ row }: CellProps<Plant>) => {
          const isActive = row.original?.is_active
          return <Chip title={isActive ? 'ACTIVE' : 'IN_ACTIVE'} />
        },
      },

      {
        Header: 'Actions',
        accessor: 'id',
        width: 100,
        Cell: ({ row }: CellProps<Plant>) => {
          return (
            <>
              {row.original.is_active && (
                <div className="flex justify-end gap-1">
                  <button
                    onClick={(event) => {
                      event.stopPropagation()
                      toggleUpdatePanel(row.original)
                    }}
                  >
                    <Pen />
                  </button>
                  <button
                    onClick={(event) => {
                      event.stopPropagation()
                      setDeletePopUp(true)
                    }}
                  >
                    <Bin />
                  </button>
                </div>
              )}
            </>
          )
        },
        disableSortBy: true,
      },
    ],
    [],
  )

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Plants</h1>
        <p className=" p-sm-regular-1">
          Upon adding a plant, you can define plant-specific products and
          processes. Easily switch between plants using the dedicated dropdown
          in the top bar for seamless navigation.
        </p>
        <FilterableSearchTable
          useOrgQuery={useGetPlantsQuery}
          addButton={{ label: 'Add Plant', onClick: handleAddPlantToggle }}
          columns={columns}
          placeholders={{
            search: 'Search plant by name or zip code',
            emptyMessage: 'No data found, please add a plant into the system.',
            filterEmptyMessage: 'No matching results found',
          }}
          filterPanelComponent={FilterPlant}
          additionalFilters={additionalFilters}
        />
        <SidePanel
          size="large"
          isOpen={isAddPlantPanelOpen}
          title="New Plant"
          onClose={() => {
            setIsAddPlantPanelOpen(false)
          }}
        >
          <AddPlant
            closePanel={() => {
              setIsAddPlantPanelOpen(false)
            }}
          />
        </SidePanel>
        {isEditPanelOpen && selectedPlant && (
          <SidePanel
            size="large"
            isOpen={isEditPanelOpen}
            title="Edit Plant"
            onClose={() => setIsEditPanelOpen(false)}
          >
            <UpdatePlant
              closePanel={() => setIsEditPanelOpen(false)}
              handleDelete={toggleDeletePanel}
              plant={selectedPlant}
            />
          </SidePanel>
        )}
        {selectedPlant && (
          <SidePanel
            size="large"
            isOpen={viewPanel}
            title="Plant Details"
            onClose={() => setViewPanel(false)}
          >
            <ViewPlant
              handleUpdate={toggleUpdatePanel}
              handleDelete={toggleDeletePanel}
              closePanel={() => setViewPanel(false)}
              plant={selectedPlant}
            />
          </SidePanel>
        )}

        <div>
          {deletePopup && selectedPlant && (
            <Popup
              isOpen={deletePopup}
              title="Delete Plant Details"
              onClose={() => {
                setDeletePopUp(false)
              }}
            >
              <DeletePlant
                closePopUp={() => {
                  setDeletePopUp(false)
                }}
                plant={selectedPlant}
              />
            </Popup>
          )}
        </div>
      </>
    </MotionDiv>
  )
}

export default Plants
