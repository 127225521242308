import { Cell } from '@tanstack/react-table'

interface Props<T> {
  cell: Cell<T, unknown>
  isLastCell: boolean
  isEvenRow: boolean
}

const BodyCell = <T,>({ cell, isLastCell, isEvenRow }: Props<T>) => {
  const cellWidth = `${cell.column.getSize()}px`
  const align = cell.column.columnDef?.meta?.['align']
  const value = cell.getValue()
  return (
    <td
      className={`border-b border-gray-100  p-xs-regular 
        ${isLastCell ? 'sticky-cell1' : ''} 
        ${isLastCell && !isEvenRow ? 'sticky-cell-even1' : ''}
        ${align === 'right' ? ' justify-end ' : 'justify-start'}
        ${align === 'center' ? ' justify-center ' : ''}
        `}
      style={{ width: cellWidth }}
    >
      <div
        className={`flex flex-row px-2 py-2
        ${align === 'right' ? ' justify-end ' : 'justify-start'}
        ${align === 'center' ? ' justify-center ' : ''}
      `}
      >
        {value ? (value as string) : '-'}
      </div>
    </td>
  )
}

export default BodyCell
