import { Dispatch, SetStateAction } from 'react'
import { useFormik } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { BaseFilters } from 'store/services/utilsTypes'
import { STATUS_OPTIONS } from 'pages/orders/sales-order/sales-order-endpoints/salesOrders.constants'
import { PurchaseOrderAdditionalFilters } from '../purchase-orders-endpoints/purchaseOrdersTypes'
import { SORT_DIR } from 'utils/constants'

interface Props {
  closePanel: () => void
  setFilters: Dispatch<
    SetStateAction<BaseFilters & PurchaseOrderAdditionalFilters>
  >
  filters: BaseFilters & PurchaseOrderAdditionalFilters
}

/**
 * Component for filtering purchase orders.
 *
 * @param {object} props - The component props.
 * @param {function} props.closePanel - Function to close the filter panel.
 * @param {function} props.setFilters - Function to set filter values for the purchase orders.
 * @param {object} props.filters - The current filters applied to the purchase orders.
 * @param {string[]} props.filters.status - The list of selected statuses for filtering.
 * @param {number[]} props.filters.update_between - The date range for the last update.
 * @param {number[]} props.filters.created_between - The date range for the creation of the order.
 */

const FilterPurchaseOrder = ({ closePanel, setFilters, filters }: Props) => {
  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      search_text: '',
      status: [],
      updated_between: [],
      created_between: [],
      sort_by: 'created_on',
      sort_dir: SORT_DIR.DESC,
    })

    closePanel()
  }

  const filterFormik = useFormik<PurchaseOrderAdditionalFilters>({
    initialValues: {
      status: filters.status ?? [],
      created_between:
        filters?.created_between?.length == 0 ? [] : filters?.created_between,
      updated_between:
        filters?.updated_between?.length == 0 ? [] : filters?.updated_between,
    },
    onSubmit: (values) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_between:
          values.created_between == null ? [] : values.created_between,
        updated_between:
          values.updated_between == null ? [] : values.updated_between,
        status: values.status,
      }))
    },
  })

  const statusOptions = STATUS_OPTIONS.map((item) => ({
    label: item.label,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Status */}
        <Field
          type="multiselect"
          label="Status"
          placeholder="Select status"
          multiSelectPlaceholderTexts={['status', 'status']}
          options={statusOptions}
          formik={filterFormik}
          name="status"
        />
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date Created"
          formik={filterFormik}
          name="created_between"
        />
        {/* Update Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Last Edited"
          formik={filterFormik}
          name="updated_between"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterPurchaseOrder
