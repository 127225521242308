import { Dispatch, SetStateAction } from 'react'
import { useFormik } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { BaseFilters } from 'store/services/utilsTypes'
import {
  CATEGORY_OPTIONS,
  STATUS_OPTIONS,
} from '../sales-order-endpoints/salesOrders.constants'
import { SalesOrderAdditionalFilters } from '../sales-order-endpoints/salesOrdersTypes'
import { SORT_DIR } from 'utils/constants'

interface Props {
  closePanel: () => void
  setFilters: Dispatch<
    SetStateAction<BaseFilters & SalesOrderAdditionalFilters>
  >
  filters: BaseFilters & SalesOrderAdditionalFilters
}

interface FormValues {
  category: string | undefined
  status: string[]
  updated_between: number[]
  created_between: number[]
}

const FilterSalesOrder = ({ closePanel, setFilters, filters }: Props) => {
  const initialValues: FormValues = {
    category: filters.category ?? undefined,
    status: filters.status ?? [],
    created_between:
      filters?.created_between?.length == 0
        ? []
        : (filters?.created_between ?? []),
    updated_between:
      filters?.updated_between?.length == 0
        ? []
        : (filters?.updated_between ?? []),
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      sort_by: 'created_on',
      sort_dir: SORT_DIR.DESC,
    })
    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_between:
          values.created_between.length <= 0 ? [] : values.created_between,
        updated_between:
          values.updated_between == null ? [] : values.updated_between,
        category: values.category,
        status: values.status,
      }))
    },
  })

  const categoryOptions = CATEGORY_OPTIONS.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  const verificatonOptions = STATUS_OPTIONS.map((item) => ({
    label: item.label,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  const selectedCategory = {
    label:
      categoryOptions.find(
        (option) => option.value === filterFormik?.values?.category,
      )?.label || '',
    value: filterFormik?.values?.category,
  }

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="select"
          label="Category"
          placeholder="Select category"
          options={categoryOptions}
          formik={filterFormik}
          name="category"
          defaultValue={
            filterFormik?.values?.category !== null ? selectedCategory : null
          }
        />
        {/* Status */}
        <Field
          type="multiselect"
          label="Status"
          placeholder="Select status"
          options={verificatonOptions}
          formik={filterFormik}
          name="status"
        />
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date Created"
          formik={filterFormik}
          name="created_between"
        />
        {/* Update Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Last Edited"
          formik={filterFormik}
          name="updated_between"
        />
      </div>
      <div className="flex justify-between pt-5">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>
          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterSalesOrder
