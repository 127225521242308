import React, { Dispatch, SetStateAction } from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { BaseFilters } from 'store/services/utilsTypes'
import { VENDOR_TYPE_OPTIONS } from '../vendor-endpoints/vendor.constants'
import { VendorAdditionalFilters } from '../vendor-endpoints/vendorTypes'
import { SORT_DIR } from 'utils/constants'

interface Props {
  closePanel: () => void
  setFilters: Dispatch<SetStateAction<BaseFilters & VendorAdditionalFilters>>
  filters: BaseFilters & VendorAdditionalFilters
}

const VendorFilterPanel: React.FC<Props> = ({
  closePanel,
  setFilters,
  filters,
}: Props) => {
  const initialValues: VendorAdditionalFilters = {
    vendors_types: filters.vendors_types ?? [],
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      search_text: '',
      vendors_types: [],
      sort_by: 'created_on',
      sort_dir: SORT_DIR.DESC,
    })
    closePanel()
  }

  const filterFormik = useFormik<VendorAdditionalFilters>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        vendors_types: values.vendors_types == null ? [] : values.vendors_types,
      }))
    },
  })

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Vendor Type"
          placeholder="Select vendor type"
          options={VENDOR_TYPE_OPTIONS}
          formik={filterFormik}
          name="vendors_types"
        />

        <div className="flex justify-between pt-5 ">
          <div>
            <Button
              onClick={() => {
                handleReset()
              }}
            >
              Reset
            </Button>
          </div>
          <div className="flex gap-2">
            <Button onClick={closePanel}>Cancel</Button>

            <Button
              color="primary"
              type="submit"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default VendorFilterPanel
