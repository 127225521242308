import React from 'react'
import { Dispatch, SetStateAction } from 'react'

import { useFormik } from 'formik'

import Field from 'libs/field'
import Button from 'libs/button/Button'
import {
  useGetCountryQuery,
  useGetTimezoneQuery,
} from 'pages/plants/plants-endpoints/plants.endpoints'
import { BaseFilters } from 'store/services/utilsTypes'
import { PlantAdditionalFilters } from '../plants-endpoints/plantsTypes'
import { SORT_DIR } from 'utils/constants'

interface Props {
  closePanel: () => void
  setFilters: Dispatch<SetStateAction<BaseFilters & PlantAdditionalFilters>>
  filters: BaseFilters & PlantAdditionalFilters
}

const FilterPlant: React.FC<Props> = ({
  closePanel,
  setFilters,
  filters,
}: Props) => {
  const { data: country } = useGetCountryQuery()

  const initialValues: PlantAdditionalFilters = {
    timezone: filters.timezone ?? [],
    country: filters.country ?? [],
    status: filters.status ?? [],
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      search_text: '',
      timezone: [],
      country: [],
      status: [],
      sort_by: 'created_on',
      sort_dir: SORT_DIR.DESC,
    })
    closePanel()
  }

  const filterFormik = useFormik<PlantAdditionalFilters>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: BaseFilters) => ({
        ...prevFilters,
        status: values.status,
        timezone: values.timezone,
        country: values.country,
      }))
    },
  })

  const status = [
    {
      name: 'Active',
      label: 'active',
      id: '101',
    },
    {
      name: 'Inactive',
      label: 'inactive',
      id: '102',
    },
  ]
  // These country  does not contain timezone
  const countryOptions = country
    ? Object.entries(country)
        .map(([key, value]) => ({
          label: value as string,
          value: key as string,
          sublabel: key as string,
          disabled: '',
        }))
        .filter((option) => option.value !== 'BV' && option.value !== 'HM')
    : []

  const timezoneFilters = {
    country_names: filterFormik?.values.country,
  }
  const { data: timezone } = useGetTimezoneQuery(
    { filters: timezoneFilters },
    {
      skip:
        filterFormik.values.country && filterFormik.values.country.length === 0,
    },
  )
  const transformedTimeZones = timezone?.map((item: string) => ({
    label: item as string,
    value: item,
  }))
  const statusOptions = status?.map((item) => ({
    label: item.name,
    value: item.label,
    sublabel: '',
    disabled: '',
  }))
  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Country */}
        <Field
          type="multiselect"
          label="Country"
          placeholder="Select country"
          itemType="country"
          options={countryOptions}
          formik={filterFormik}
          name="country"
        />
        {/* Timezone */}
        <Field
          type="multiselect"
          label="Timezone"
          placeholder="Select timezone"
          itemType="timezone"
          options={transformedTimeZones}
          formik={filterFormik}
          disabled={filterFormik?.values?.country?.length === 0}
          name="timezone"
        />

        {/* Status */}
        <Field
          type="multiselect"
          label="Status"
          placeholder="Status"
          itemType="status"
          options={statusOptions}
          formik={filterFormik}
          name="status"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterPlant
