import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Chip from 'libs/chip/Chip'
import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import { hasAccess } from 'utils/utils'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetVendorByIdQuery } from '../vendor-endpoints/vendor.endpoints'
import { Address } from 'pages/contacts/commonTypes'
import { formatString } from 'utils/stringHandlers'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import PaginatedTableV2 from 'libs/table/paginated-table/PaginatedTableV2'

const ViewAddressDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const vendorAccess = hasAccess('con', { ven: 'e' })

  const plant = useSelector(selectSelectedItem)

  const { data: vendor, isLoading } = useGetVendorByIdQuery(
    { plant_id: plant?.id, id: id ?? '' },
    {
      skip: !plant?.id || !id,
    },
  )

  const columns: ColumnDef<Address>[] = useMemo(
    () => [
      {
        header: 'Address type',
        accessorFn: (row) => (
          <div className="flex items-center">
            {formatString(row?.addressType)}
            {row.isPrimary ? (
              <div className="ml-2">
                <Chip title="PRIMARY" />{' '}
              </div>
            ) : null}
          </div>
        ),
        enableSorting: true,
      },
      {
        header: 'Address line 1',
        accessorKey: 'addressLine1',
      },
      {
        header: 'Address line 2',
        accessorKey: 'addressLine2',
      },
      {
        minSize: 250,
        header: 'City',
        accessorKey: 'city',
        meta: {
          align: 'right',
        },
      },
      {
        header: 'State',
        accessorKey: 'state',
      },
      {
        header: 'Zip Code',
        accessorKey: 'zipCode',
      },
      {
        header: 'Country',
        accessorKey: 'country',
      },
    ],
    [],
  )

  return (
    <MotionDiv>
      <div className="flex w-full flex-col  gap-4 ">
        <div className="flex w-full items-center justify-between">
          <h5 className="h5">Address Details</h5>
          {vendorAccess && (
            <Button
              color="primary"
              onClick={() => {
                id &&
                  navigate(
                    `/contacts/vendor-management/edit/${id}/address-details`,
                  )
              }}
            >
              Edit Address Details
            </Button>
          )}
        </div>
        <PaginatedTableV2<Address>
          columns={columns}
          loading={isLoading}
          rows={vendor?.addresses ?? []}
          hidePagination={true}
          emptyMessage={
            'No Address details have been added yet. Please add new ones'
          }
        />
      </div>
    </MotionDiv>
  )
}

export default ViewAddressDetails
