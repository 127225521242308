import { useFormik } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { CustomerAdditionalFilters } from '../customer-endpoints/customerTypes'
import { BaseFilters } from 'store/services/utilsTypes'
import { Dispatch, SetStateAction } from 'react'
import { CUSTOMER_TYPE_OPTIONS } from '../customer-endpoints/customer.constants'
import { SORT_DIR } from 'utils/constants'

interface Props {
  closePanel: () => void
  setFilters: Dispatch<SetStateAction<BaseFilters & CustomerAdditionalFilters>>
  filters: BaseFilters & CustomerAdditionalFilters
}

const CustomerFilterPanel = ({ closePanel, filters, setFilters }: Props) => {
  const initialValues = {
    customer_types: filters.customer_types ?? [],
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      search_text: '',
      customer_types: [],
      sort_by: 'updated_on',
      sort_dir: SORT_DIR.DESC,
    })
    closePanel()
  }

  const filterFormik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        customer_types: values?.customer_types,
      }))
    },
  })

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Customer Type"
          placeholder="Select customer type"
          options={CUSTOMER_TYPE_OPTIONS}
          formik={filterFormik}
          name="customer_types"
        />
      </div>
      <div className="flex justify-between pt-5">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset Filters
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>
          <Button
            type="submit"
            color="primary"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default CustomerFilterPanel
