import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'

import { toast } from 'libs/toast'
import Button from 'libs/button/Button'

import { useDeleteWarehouseMutation } from '../warehouses-endpoints/warehouses.endpoints'
import { Warehouse } from '../warehouses-endpoints/warehouseTypes'

interface Props {
  closePopUp: () => void
  warehouse: Warehouse
}

const DeleteWarehouse = ({ closePopUp, warehouse }: Props) => {
  const selectedPlant = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const [deleteWarehouse, { isLoading }] = useDeleteWarehouseMutation()
  const handleDelete = () => {
    deleteWarehouse({
      id: warehouse?.id,
      plant_id: selectedPlant?.id,
    })
      .unwrap()
      .then(() => {
        toast.success(
          `The Warehouse “${warehouse?.name}” is deleted successfully.`,
        )
        closePopUp()
        navigate('/settings/configure/warehouses')
      })
      .catch((err) => {
        toast.error(
          err?.data?.detail ??
            `Failed to delete Warehouse “${warehouse?.name}”. Please try again.`,
        )
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('warehouse.warehouse_delete_message')}{' '}
        <span className="break-words p-xs-bold">“{warehouse?.name}”. </span>
        {t('warehouse.warehouse_delete')}
      </p>

      <div className="mt-4 flex justify-end gap-2">
        <Button onClick={closePopUp}>{t('warehouse.close')}</Button>
        <Button
          color="error"
          type="button"
          loading={isLoading}
          onClick={handleDelete}
        >
          {t('warehouse.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeleteWarehouse
