import Filter from 'assets/icons/camera-page/Filter'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import Button from 'libs/button/Button'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import PaginatedTable from 'libs/table/PaginatedTable'
import SidePanel from 'libs/sidepanel/SidePanel'
import SearchBox from 'libs/searchbox/SearchBox'
import WhiteCard from 'libs/card/WhiteCard'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedSearch } from 'libs/hooks/useSearch'
import { FilterableSearchTableProps } from './FilterableSearchTableTypes'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'
import Cookies from 'js-cookie'

const FilterableSearchTable = ({
  useQuery,
  useOrgQuery,
  columns,
  additionalFilters,
  placeholders,
  addButton,
  excludeKeys = [],
  expandableRow,
  accessRights,
  onDoubleClick,
  filterPanelComponent: FilterPanelComponent,
  showCheckbox = false,
  onSingleClick,
  batchData,
  onSelectAll,
}: FilterableSearchTableProps) => {
  const plant = useSelector(selectSelectedItem)
  const [key, setKey] = useState(0)
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch()
  const [showFilter, setShowFilter] = useState(false)
  const { data: userInfo } = useUserInfoQuery({})
  const [role, setRole] = useState(undefined)

  const [filters, setFilters] = useState({
    page_no: 1,
    page_size: 10,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
    ...additionalFilters,
  })
  //*************************************This API will be called for the perticular plant **************************** */
  const paginatedQueryResult = useQuery
    ? useQuery(
        {
          plantId: plant?.id,
          filters: filters,
        },
        {
          skip: !plant?.id,
        },
      )
    : { data: null, isFetching: false }

  //**********************************************This API will be called for perticular organization ******************* */

  const orgQueryResult = useOrgQuery
    ? useOrgQuery(
        {
          org_id: userInfo?.organization?._id,
          filters: filters,
        },
        {
          skip: !userInfo?.organization?._id,
          refetchOnMountOrArgChange: true,
        },
      )
    : { data: null, isFetching: false }

  const { data: paginatedData, isFetching: isPaginatedTableFetching } =
    paginatedQueryResult

  const { data: plantData, isFetching: isPlantDataFetching } = orgQueryResult

  //Calculate active filter count
  const calculateActiveFiltersCount = (filters: any) => {
    const excludedKeys = [
      'page_no',
      'page_size',
      'searchText',
      'sortBy',
      'sortDir',
      ...excludeKeys,
    ]
    let count = 0
    for (const key in filters) {
      if (excludedKeys.includes(key)) continue
      if (Array.isArray(filters[key]) && filters[key].length > 0) {
        count += 1
      } else if (filters[key] && !Array.isArray(filters[key])) {
        count += 1
      }
    }
    return count
  }

  const activeFiltersCount = calculateActiveFiltersCount(filters)

  //search
  useEffect(() => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: 1,
      searchText: searchValue ? searchValue : '',
    }))
  }, [searchValue])

  const handlePageChange = useCallback((page_no: number) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: page_no,
    }))
    setKey((prevKey) => prevKey + 1)
  }, [])

  // handle sort for column
  const handleSort = (columnName: string) => {
    setFilters((prevFilters: any) => {
      const newSortDir =
        prevFilters?.sortBy === columnName
          ? prevFilters?.sortDir === 'ASC'
            ? 'DESC'
            : 'ASC'
          : 'ASC'
      return {
        ...prevFilters,
        sortBy: columnName,
        sortDir: newSortDir,
      }
    })
  }

  const handlePageSizeChange = (newPageSize: number) => {
    const totalItemsCount =
      paginatedData != null
        ? paginatedData?.paging?.total_items_count
        : plantData?.paging?.total_items_count
    const currentPage = filters.page_no
    const startIndex = (currentPage - 1) * filters.page_size + 1
    const totalPages = totalItemsCount
      ? Math.ceil(totalItemsCount / newPageSize)
      : 0
    let newPageNo = Math.ceil(startIndex / newPageSize)
    newPageNo = Math.min(newPageNo, totalPages)
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: newPageNo,
      page_size: newPageSize,
    }))
    setKey((prevKey) => prevKey + 1)
  }

  useEffect(() => {
    setRole(Cookies.get('role'))
  }, [Cookies.get('role')])

  return (
    <WhiteCard className="mt-4">
      <div className="mb-2 flex justify-between">
        <div className="flex w-full gap-3">
          {FilterPanelComponent != null && (
            <Button
              onClick={() => setShowFilter(!showFilter)}
              startIcon={<Filter color="#3D4140" />}
            >
              Filters{' '}
              {activeFiltersCount === 0 ? '' : `(${activeFiltersCount})`}
            </Button>
          )}
          {placeholders.search && (
            <SearchBox
              value={inputValue}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={placeholders.search}
              onClearSearchBox={() => {
                setFilters((prevFilters: any) => ({
                  ...prevFilters,
                  searchText: '',
                }))
                handleSearch('')
              }}
            />
          )}
        </div>
        <div className="relative mx-2 flex gap-3">
          {/* <ExportButton onOptionSelect={() => {}} disabled>
            {t('export_data')}
          </ExportButton>
          <ImportButton disabled onOptionSelect={() => {}}>
            {t('import_data')}
          </ImportButton>*/}
          {addButton != null && (accessRights || role == 'admin') && (
            <Button
              startIcon={<PlusIcon />}
              color="primary"
              data-testid="Add-Button"
              onClick={addButton.onClick}
            >
              {addButton.label}
            </Button>
          )}
        </div>
      </div>
      <PaginatedTable
        onPageNumberChange={handlePageChange}
        key={key}
        columns={columns}
        sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
        rows={paginatedData?.data || plantData?.data}
        currentPageNumber={filters.page_no}
        pageSize={filters.page_size}
        showArrow={expandableRow ? expandableRow : false}
        subRow={expandableRow ? expandableRow : false}
        onPageSizeChange={handlePageSizeChange}
        onDoubleClick={onDoubleClick}
        loading={isPaginatedTableFetching || isPlantDataFetching}
        pagination={paginatedData?.paging || plantData?.paging}
        emptyMessage={`${filters.searchText ? `${placeholders.filterEmptyMessage}` : activeFiltersCount > 0 ? placeholders.filterEmptyMessage : placeholders.emptyMessage}`}
        onColumnSort={handleSort}
        showCheckbox={showCheckbox}
        onSingleClick={onSingleClick}
        batchData={batchData}
        onSelectAll={onSelectAll}
      />

      {FilterPanelComponent != null && (
        <SidePanel
          size="large"
          isOpen={showFilter}
          title="Filters"
          onClose={() => {
            setShowFilter(false)
          }}
        >
          <FilterPanelComponent
            closePanel={() => {
              setShowFilter(false)
            }}
            filters={filters}
            setFilters={setFilters}
          />
        </SidePanel>
      )}
    </WhiteCard>
  )
}

export default FilterableSearchTable
