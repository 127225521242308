import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Field from 'libs/field'
import MotionDiv from 'libs/motionDiv'
import { selectSelectedItem } from 'store/redux/navSlice'
import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import WhiteCard from 'libs/card/WhiteCard'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import NewEditableTable from 'libs/table/NewEditableTable'
import {
  useCreateWarehouseMutation,
  useUpdateWarehouseMutation,
  useGetWarehouseByIdQuery,
} from '../warehouses-endpoints/warehouses.endpoints'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { WarehouseFormik } from '../warehouses-endpoints/warehouseTypes'

const productsFilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'created_on',
  sortDir: 'DESC',
  searchText: '',
}

const AddEditWarehouses = () => {
  const { id } = useParams()
  useTabTitle(
    `${id ? t('warehouse.edit_warehouse') : t('warehouse.add_warehouse')}`,
  )

  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  
  const [error, setError] = useState(false)
  const [data, setData] = useState<
    {
      _id?: string
      name: string
      products: string
    }[]
  >([])

  const [addWarehouse, { isLoading: loadingWarehouse }] =
    useCreateWarehouseMutation()

  const [editWarehouse, { isLoading: loadingEditWarehouse }] =
    useUpdateWarehouseMutation()

  const { data: warehouse, isLoading: isWarehouseLoading } =
    useGetWarehouseByIdQuery(
      {
        id: id ?? '',
        plant_id: plant?.id,
      },
      {
        skip: !plant.id || !id,
      },
    )

  const { data: products, isLoading: isProductsLoading } = useGetProductQuery({
    plantId: plant?.id,
    filters: productsFilters,
  })

  const productOptions = useMemo(
    () =>
      // @ts-ignore
      products?.data?.map((item) => ({
        id: item._id,
        label: item.name,
        value: item._id,
      })),
    [products],
  )

  const columns = useMemo(
    () => [
      {
        title: 'Storage Area',
        accessor: 'name',
        editable: true,
        placeholder: 'Enter storage area name',
      },

      {
        flex: 2,
        title: 'Allowed Products',
        accessor: 'products',
        fieldType: 'select',
        options: productOptions,
        editable: true,
        placeholder: 'Select Products',
      },
    ],
    [productOptions],
  )

  const warehouseFormik = useFormik<WarehouseFormik>({
    initialValues: {
      name: '',
      storage_areas: [],
    },
    validationSchema: yup.object({
      name: yup.string().required(t('warehouse.warehouse_error')),
    }),
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        storage_areas: values.storage_areas.map((area) => ({
          id: id ? area.id : undefined,
          name: area.name.trim(),
          products: area.products,
        })),
      }

      if (id && !error) {
        editWarehouse({
          data: payload,
          plant_id: plant?.id,
          id: id,
        })
          .unwrap()
          .then((res) => {
            toast.success(res)
            navigate('/settings/configure/warehouses')
          })
          .catch((err) => {
            toast.error(
              err?.data?.detail ?? 'Something went wrong. Please try again.',
            )
          })
      } else if (!error) {
        addWarehouse({
          data: payload,
          plant_id: plant?.id,
        })
          .unwrap()
          .then((res) => {
            toast.success(res)
            navigate('/settings/configure/warehouses')
          })
          .catch((err) => {
            toast.error(
              err?.data?.detail ?? 'Something went wrong. Please try again.',
            )
          })
      }
    },
  })

  useEffect(() => {
    if (warehouse && products) {
      warehouseFormik.resetForm({
        values: {
          name: warehouse?.name,
          // @ts-ignore
          storage_areas: warehouse?.storage_areas?.map((area) => ({
            id: area.id ? area.id : '',
            name: area?.name,
            products: area.products.map((p) => {
              return p.id
            })[0],
          })),
        },
      })
    }
  }, [warehouse, products])

  useEffect(() => {
    if (data && data[0]?.products) {
      setError(false)
    }
  }, [data])

  const handleAddress = useCallback(() => {
    if ((data && !data[0].products) || !data) {
      setError(true)
    } else {
      warehouseFormik?.setFieldValue(
        'storage_areas',
        data
          ? data.map((area) => {
              return {
                id: typeof area?._id === 'string' ? area?._id : undefined,
                name: area.name,
                products: [
                  {
                    id: area.products,
                  },
                ],
              }
            })
          : [],
      )
    }
    warehouseFormik.handleSubmit()
  }, [data])

  return (
    <MotionDiv>
      <>
        <h1 className="mb-2 h4-bold-black ">
          {id ? t('warehouse.edit_warehouse') : t('warehouse.new_warehouse')}
        </h1>
        {isProductsLoading || isWarehouseLoading ? (
          <FullPageLoader />
        ) : (
          <WhiteCard className="gap-4">
            <div>
              <h1 className="h5">{t('warehouse.warehouse_details')}</h1>
              <p className="p-sm-regular-1">
                {id
                  ? t('warehouse.edit_description')
                  : t('warehouse.add_description')}
              </p>
            </div>
            <div className="w-[500px]">
              <Field
                required={true}
                label={t('warehouse.warehouse_name')}
                formik={warehouseFormik}
                name="name"
                placeholder={t('warehouse.warehouse_name_placeholder')}
              />
            </div>

            <hr></hr>

            <div className="mt-4">
              <NewEditableTable
                defaultRows={warehouseFormik?.values?.storage_areas}
                columns={columns}
                addButtonTitle="Add Storage area"
                errorType={error}
                errorMessage="Error: To save the warehouse, you must add at least one storage area. Please add a storage area with allowed products."
                onChange={(values) => {
                  setData(values)
                }}
              />
            </div>
            <div className="flex h-full justify-end gap-3">
              <Button onClick={() => navigate(-1)}>
                {t('warehouse.cancel')}
              </Button>
              <Button
                color="success"
                onClick={() => {
                  handleAddress()
                }}
                loading={id ? loadingEditWarehouse : loadingWarehouse}
              >
                Save
              </Button>
            </div>
          </WhiteCard>
        )}
      </>
    </MotionDiv>
  )
}

export default AddEditWarehouses
