import { useMemo } from 'react'
import { t } from 'i18next'
import { CellProps } from 'react-table'

import PaginatedTable from 'libs/table/PaginatedTable'
import { ExtendedColumn } from 'store/services/utilsTypes'
import { getRelativeTime } from 'utils/dateTimeHandlers'
import { StorageArea, Warehouse } from '../warehouses-endpoints/warehouseTypes'

interface Props {
  warehouse: Warehouse
}

const StorageView = ({ warehouse }: Props) => {
  const columns: ExtendedColumn<StorageArea>[] = useMemo(
    () => [
      {
        Header: t('warehouse.storage_area_name'),
        accessor: 'name',
        width: 200,
        disableSortBy: true,
      },
      {
        Header: t('warehouse.allow_products_name'),
        width: 250,
        accessor: 'products',
        Cell: ({ row }: CellProps<StorageArea>) => {
          return <div>{row?.original?.products.length ?? '-'}</div>
        },
        disableSortBy: true,
      },
    ],
    [],
  )

  return (
    <div>
      <div className="flex flex-col gap-2 rounded bg-gray-100 p-3">
        <div className="flex justify-between">
          <div className=" p-xs-regular-dim" data-testid ="warehouse_name">Warehouse</div>
          <div>
            <div className="w-60 break-words p-xs-regular">
              {warehouse?.name ?? '-'}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="p-xs-regular-dim">Last updated</div>
          <div>
            <div className="w-60 p-xs-regular">
              {warehouse?.updated_on
                ? getRelativeTime(warehouse?.updated_on)
                : '-'}
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="p-xs-regular-dim">Updated by</div>
          <div>
            <div className="w-60 p-xs-regular">
              {warehouse?.updated_on
                ? getRelativeTime(warehouse?.updated_on)
                : '-'}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <PaginatedTable
          columns={columns}
          rows={warehouse?.storage_areas}
          hidePagination={true}
        />
      </div>
    </div>
  )
}

export default StorageView
