import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Chip from 'libs/chip/Chip'
import Popup from 'libs/popup/Popup'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import FilterableSearchableTable from 'libs/table/filterable-searchable-table/FilterableSearchableTable'
import { hasAccess } from 'utils/utils'
import { formatString } from 'utils/stringHandlers'
import { countryPhoneNumber } from 'utils/phoneNumberValidator'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import { useGetVendorsQuery } from './vendor-endpoints/vendor.endpoints'
import VendorFilterPanel from './panel/VendorFilterPanel'
import DeleteVendor from './delete-vendor/DeleteVendor'
import { Vendor } from './vendor-endpoints/vendorTypes'
import { ColumnDef } from '@tanstack/react-table'

const filters = {
  vendorType: [],
}

const VendorManagement = () => {
  useTabTitle('Vendor Management')
  const navigate = useNavigate()
  const hasEditAccess = hasAccess('ord', { so: 'e' })

  const [deletePopUp, setDeletePopUp] = useState<{
    isPopUpOpen: boolean
    VendorData: Vendor | null
  }>({
    isPopUpOpen: false,
    VendorData: null,
  })

  const vendorAccess = hasAccess('con', { ven: 'v' })
  const vendorEditAccess = hasAccess('con', { ven: 'e' })

  const columns: ColumnDef<Vendor>[] = useMemo(
    () => [
      {
        id: 'name',
        header: 'Vendor Name',
        accessorFn: (row) => {
          const vendor = row
          return (
            <div
              className={`${vendorAccess ? 'hyperlink' : ''}`}
              onClick={() => {
                vendorAccess &&
                  vendor?.id &&
                  navigate(
                    `/contacts/vendor-management/view/${vendor.id}/view-basic-details`,
                  )
              }}
            >
              {vendor?.name ?? '-'}
            </div>
          )
        },
        enableSorting: true,
      },
      {
        header: 'Vendor Type',
        accessorKey: 'vendorType',
        accessorFn: (row) => formatString(row.vendorType),
        enableSorting: true,
      },
      {
        id: 'category',
        header: 'Category',
        accessorFn: (row) => <Chip title={row.category} />,
        enableSorting: true,
      },
      {
        header: 'Contact Number',
        accessorFn: (row) => {
          const { phoneNumber } = countryPhoneNumber(row.primaryContactNumber)
          return <span>{phoneNumber ?? '-'}</span>
        },
      },
      {
        header: 'Email',
        accessorKey: 'businessEmailAddress',
        enableSorting: true,
      },
      {
        header: 'Primary Address',
        accessorFn: (row) => {
          const value = row.addresses
          if (value && value.length > 0) {
            const primaryAddress =
              value?.find((address) => address?.isPrimary) || value[0]
            return (
              <div>
                <p>
                  {primaryAddress?.addressLine1}, {primaryAddress?.city}
                </p>
              </div>
            )
          } else {
            return <div>-</div>
          }
        },
        disableSortBy: true,
      },
      {
        header: 'Actions',
        minSize: 100,
        maxSize: 100,
        accessorFn: (row) => {
          return (
            <div className="flex w-full items-center justify-between">
              <span className="flex">
                <button
                  className={`mr-2 ${vendorAccess ? '' : 'opacity-40'}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    vendorEditAccess &&
                      row?.id &&
                      navigate(`edit/${row.id}/basic-details`)
                  }}
                >
                  <Pen />
                </button>
                <button
                  className={`mr-2 ${vendorAccess ? '' : 'opacity-40'}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    vendorEditAccess &&
                      row &&
                      setDeletePopUp({
                        isPopUpOpen: true,
                        VendorData: row,
                      })
                  }}
                >
                  <Bin />
                </button>
              </span>

              <span
                className="cursor-pointer"
                onClick={() =>
                  vendorAccess &&
                  row?.id &&
                  navigate(
                    `/contacts/vendor-management/view/${row.id}/view-basic-details`,
                  )
                }
              >
                <ClickableRowArrow />
              </span>
            </div>
          )
        },
        disableSortBy: true,
      },
    ],
    [navigate, vendorAccess, vendorEditAccess],
  )

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">Vendor Management</h1>
        <p className="p-sm-regular-1">
          View the details of your added Vendor and easily add new ones as
          needed.
        </p>

        <FilterableSearchableTable
          useQuery={useGetVendorsQuery}
          columns={columns}
          addButton={{
            label: 'Add Vendor',
            onClick: () => navigate('add/basic-details'),
          }}
          placeholders={{
            emptyMessage: 'No vendors have been added. Please add a vendor.',
            filterEmptyMessage: 'No matching results found.',
            search: 'Search Vendor by Name, Type, Contact Number or Email',
          }}
          filterPanelComponent={VendorFilterPanel}
          additionalFilters={filters}
          hasModifyAccess={hasEditAccess}
        />
        {deletePopUp?.VendorData && (
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Vendor"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, VendorData: null })
            }}
          >
            <DeleteVendor
              closePopUp={() =>
                setDeletePopUp({ isPopUpOpen: false, VendorData: null })
              }
              vendor={deletePopUp?.VendorData}
            />
          </Popup>
        )}
      </>
    </MotionDiv>
  )
}

export default VendorManagement
